<template>
  <div class="ori-event">
    <ori-header />

    <div class="banner">
      <el-carousel height="360px" :autoplay="true">
        <el-carousel-item v-for="(item, index) in imgs" :key="index">
          <img height="360px" width="100%" :src="item" alt="" srcset="" />
        </el-carousel-item>
      </el-carousel>
    </div>

    <match-filter />

    <div class="match-list-wrap">
      <div class="match-content w1200">
        <el-row :gutter="15">
          <el-col :span="8" v-for="i in list" :key="i.id">
            <div class="match-box">
              <div class="poster">
                <img :src="i.poster" alt="" srcset="" />
              </div>
              <div class="info">
                <div class="title">{{ i.title }}</div>
                <div class="add solo-row">
                  <span>
                    <i class="el-icon-location-outline"></i>
                    活动地点：
                  </span>
                  <span>
                    {{ i.add }}
                  </span>
                </div>
                <div class="time solo-row">
                  <span>
                    <i class="el-icon-time"></i>
                    报名时间：
                  </span>
                  <span> {{ i.start }} - {{ i.end }} </span>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <!-- 分页 -->
    <div class="w1200 pagination-wrap">
      <ori-pagination />
    </div>

    <!-- 页脚 -->
    <page-foot />
  </div>
</template>

<script>
import { matchList } from "@/Mock";
import MatchFilter from "../../../components/ori-com/Select/MatchFilter.vue";
import PageFoot from "../../../components/ori-com/PageFoot.vue";

export default {
  components: { MatchFilter, PageFoot },
  name: "ori-event",
  data() {
    return {
      list: matchList.list,
      imgs: [
        require("@/assets/images/ori/202108242321148455.png"),
        require("@/assets/images/ori/202108131106516313.jpg"),
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.ori-event {
  width: 100%;
  overflow: hidden;
  background-color: #f9f9f9;
  .banner {
    width: 100%;
    overflow: hidden;
  }

  .match-list-wrap {
    margin-top: 20px;

    .match-content {
      .match-box {
        cursor: pointer;
        background-color: #fff;
        margin-bottom: 18px;

        &:hover,
        &:active {
          box-shadow: 0 0 15px #ddd;
          .poster img {
            transform: scale(1.2);
          }
        }

        .poster {
          width: 390px;
          height: 230px;
          overflow: hidden;
          img {
            transition: 0.2s all ease-in;
          }
        }

        .info {
          overflow: hidden;
          position: relative;
          padding: 11px 16px;

          .title {
            font-size: 18px;
            font-weight: bold;
            margin: 0 0 8px 0;
            overflow: hidden;
            text-overflow: ellipsis;

            // 最多两行
            display: -webkit-box !important;
            -webkit-box-orient: vertical;
            line-clamp: 2 !important;
            -webkit-line-clamp: 2 !important;

            height: 46px !important;
            display: inline-block;
            white-space: normal !important;
            line-height: 24px;
          }

          .add,
          .time {
            font-size: 12px;
            height: 25px;
            line-height: 25px;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #666;
            span:nth-child(1) {
              color: #999;
              i {
                font-size: 16px;
                vertical-align: middle;
              }
            }
          }
        }
      }
    }
  }

  .pagination-wrap {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 15px 0;
    display: flex;
    justify-content: center;
  }
}
</style>